import React from "react";
import { VariantProps, cva } from "class-variance-authority";

/// Helper types to support the polymorphic pattern.
type AsProp<T extends React.ElementType> = {
  as?: T;
};

type PropsToOmit<T extends React.ElementType, P> = keyof (AsProp<T> & P);

export type PolymorphicComponentProps<
  T extends React.ElementType,
  Props = object
> = React.PropsWithChildren<Props & AsProp<T>> &
  Omit<React.ComponentPropsWithoutRef<T>, PropsToOmit<T, Props>>;

export type PolymorphicRef<T extends React.ElementType> = React.ComponentPropsWithRef<T>["ref"];
export type TextVariant = VariantProps<typeof textStyles>["variant"];
export type TextColor = VariantProps<typeof textStyles>["color"];

// Define your custom props.
interface TextOwnProps {
  variant?: TextVariant;
  color?: TextColor;
  noWrap?: boolean;
  className?: string;
}

// Combine custom props with the intrinsic props based on the `as` prop.
export type TextProps<T extends React.ElementType = "span"> = PolymorphicComponentProps<
  T,
  TextOwnProps
>;

const textStyles = cva("", {
  variants: {
    variant: {
      // Scores
      scoreXl: "Text_scoreXl font-roboto-mono text-4xl not-italic font-light leading-2xl",
      scoreL: "Text_scoreL font-roboto-mono text-2xl non-italic font-light leading-xl",
      scoreM: "Text_scoreM font-roboto-mono text-xl non-italic font-light leading-l",
      // Titles
      primaryTitle: "Text_primaryTitle font-arial text-2xl not-italic font-normal leading-2xl",
      secondaryTitle: "Text_secondaryTitle font-roboto text-xl non-italic font-medium leading-xl",
      tertiaryTitle: "Text_tertiaryTitle font-roboto text-l non-italic font-medium leading-l",
      // Body
      bodyTitle: "Text_bodyTitle font-roboto text-m non-italic font-medium leading-m",
      bodyText: "Text_bodyText font-roboto text-m non-italic font-normal leading-m",
      bodyLink:
        "Text_bodyLink font-roboto text-m non-italic font-normal leading-m underline text-text-links",
      bodyNumeric: "Text_bodyNumeric font-roboto-mono text-m non-italic font-medium leading-m",
      // Subtext
      subtextTitle: "Text_subtextTitle font-roboto text-s non-italic font-medium leading-s",
      subtextText: "Text_subtextText font-roboto text-s non-italic font-normal leading-s",
      subtextLink:
        "Text_subtextLink font-roboto text-s non-italic font-normal leading-s underline text-text-links",
      subtextNumeric:
        "Text_subtextNumeric font-roboto-mono text-s non-italic font-normal leading-s",
      // Inherit styles from parents, you won't usually need this
      inherit: "",
    },
    // can be overriten by className if no color is specified
    color: {
      primary: "text-text-primary",
      secondary: "text-text-secondary",
      tertiary: "text-text-tertiary",
      links: "text-text-links",
      disabled: "text-text-disabled",
      subtle: "text-text-subtle",
    },
    noWrap: {
      true: "whitespace-nowrap overflow-hidden text-ellipsis inline-block w-full",
      false: "whitespace-normal",
    },
  },
  defaultVariants: {
    variant: "bodyText",
  },
});

// --- Polymorphic Text Component ---
// To get proper generic inference in TSX we declare an explicit call signature.
type TextComponent = <T extends React.ElementType = "span">(
  props: TextProps<T> & { ref?: PolymorphicRef<T> }
) => React.ReactElement | null;

export const Text: TextComponent = React.forwardRef(function Text<
  T extends React.ElementType = "span"
>(
  { as, variant, color, className, children, noWrap, ...props }: TextProps<T>,
  ref: PolymorphicRef<T>
) {
  // Default to "span" if no "as" is provided.
  const Component = as || "span";
  return (
    <Component
      {...props}
      className={textStyles({
        variant,
        color,
        noWrap,
        className,
      })}
      ref={ref}
    >
      {children}
    </Component>
  );
}) as TextComponent;
